<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3">
          <b-form-group label="Title" label-for="searchTerm">
            <b-form-input
              v-on:keyup="onSearch"
              v-on:input="assetFiltering"
              v-model.lazy="searchTerm"
              placeholder="Search Asset By Title ..."
              type="text"
              class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Asset Type" label-for="filterAssetTypeId">
            <v-select
              id="filterAssetTypeId"
              placeholder="Choose Asset Type"
              v-model="filter_asset_type"
              v-on:input="assetFiltering"
              :options="assetTypeConstant"
              :reduce="(option) => option.value"
              class="custom-font"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Department" label-for="departmentId">
            <v-select
              id="departmentId"
              @open="loadDepartments"
              placeholder="Choose Department"
              v-model="department_id"
              v-on:input="assetFiltering"
              :options="selectDepartmentIdOptions"
              :reduce="(option) => option.id"
              class="custom-font"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Status" label-for="filteredStatus">
            <v-select
              @open="loadDepartments"
              option-disabled="disable"
              v-on:input="assetFiltering"
              id="filteredStatus"
              placeholder="Choose Status"
              v-model="filter_status"
              :options="filteredStatusValueOption"
              :reduce="(option) => option.value"
              class="custom-font"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Warranty Filter" label-for="warranty_filter">
            <flat-pickr
                id="warranty_filter"
              v-model="filterRangeDate"
              v-on:input="loadItems"
              class="form-control"
              placeholder="Enter Date Range"
              :config="{ mode: 'range', defaultDate: [this.filterStartDate, this.filterEndDate]}"
          />
          </b-form-group>
        </b-col>
        <b-col md="5">
          <b-form-group
            label="Asset Category"
            label-for="filterAssetCategoryId"
          >
            <v-select
              id="filterAssetCategoryId"
              placeholder="Choose Asset Category"
              v-model="assetCategoryId"
              v-on:input="getCategoryWiseAttr"
              :options="selectAssetCategoryIdOptions"
              :reduce="(option) => option.id"
              class="custom-font"
              label="name"
              :disabled="isCategoryDisabled"
            />
          </b-form-group>
        </b-col>

        <b-col md="1">
          <b-dropdown
            class="btn-adjust"
            id="dropdown-form"
            ref="dropdown"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            right
            variant="primary"
            text="Select"
            :disabled="isAttributeDropdownDisabled"
          >
            <b-dropdown-form class="py-1">
              <div class="mb-2">
                <b-form-group label="Filter Attribute">
                  <div class="scrollable-checkbox-group">
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selectedAttribute"
                      v-on:input="getSelectedAttribute"
                      :options="selectAttributeIdOptions"
                      name="flavour-1"
                      class="demo-inline-spacing"
                    />
                  </div>
                </b-form-group>
              </div>
            </b-dropdown-form>
            <b-dropdown-divider />
          </b-dropdown>
        </b-col>

        <!-- for category attribute -->
        <b-col
          md="4"
          v-for="(item, index) in this.categoryAttributesArray"
          :key="index"
        >
          <div v-if="assetCategoryId">
            <b-form-group :label="item.name" :label-for="item.name">
              <b-form-input
                v-on:keyup="onSearch"
                v-on:input="assetFiltering"
                v-model.lazy="searchAttributeValue[index]"
                placeholder="Search Asset  ..."
                type="text"
                class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-form-group>
          </div>
        </b-col>

        <!-- for only attribute -->
        <b-col
          md="4"
          v-for="(item, index) in this.filteredAttributes"
          :key="index"
        >
          <b-form-group :label="item.name" :label-for="item.name">
            <b-form-input
              v-on:keyup="onSearch"
              v-on:input="assetFiltering"
              v-model.lazy="searchAttributeValue[index]"
              placeholder="Search Asset  ..."
              type="text"
              class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div>
        <!-- search input -->
        <div
          class="custom-search d-flex align-items-center justify-content-end"
        >
          <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
          >
            <!-- <template
              v-if="
                $permissionAbility(ASSET_EXPORT, permissions) && rows.length > 0
              "
            >
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-on:click="exportAsset"
              >
                Export
              </b-button>
            </template> -->
            <template v-if="$permissionAbility(ASSET_CREATE, permissions)">
              <b-button
                class="flex-shrink-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-on:click="showModal"
              >
                Create
              </b-button>
            </template>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
            styleClass="vgt-table table-custom-style striped"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          :rows="rows"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'format_image'">
              <b-link v-on:click="onShowPage(props.row.id)">
                <b-img
                  :src="props?.row?.asset_image"
                  class="custom-asset-image"
                ></b-img>
              </b-link>
            </template>
            <template v-if="props?.column?.field === 'format_info'">
              <h5 class="mt-1">
                <b-link v-on:click="onShowPage(props.row.id)"
                  >{{ props?.row?.title }}
                </b-link>
              </h5>
              <div class="mt-1">
                <h6>Comp SN: <b>{{ props?.row?.company_serial_number }}</b></h6>
                <h6>
                  <b>Type: </b>
                  <b-badge variant="light-primary">{{
                    formatAssetType(props?.row?.type)
                  }}</b-badge>
                </h6>
                <h6>Category: <b>{{ props?.row?.asset_category?.name }}</b></h6>
              </div>
              <b-button
                class="btn-sm"
                variant="outline-primary"
                v-on:click="showDescriptionHistory(props.row.id)"
              >
                <feather-icon class="cursor-pointer" icon="AlertCircleIcon"
              /></b-button>
            </template>
            <template v-if="props?.column?.field === 'format_cost_info'">
              <h6>
                Purchase Cost:
                <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.cost" />
              </h6>
              <h6>
                Repair Cost:
                <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.repair_cost_sum" />
              </h6>
              <h6>
                Total Cost:
                <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.total_cost" />
              </h6>

              <b-link
                v-if="props?.row?.purchase_receipt"
                :href="props?.row?.purchase_receipt"
                target="_blank"
              >
                <FileMinusIcon />
              </b-link>
            </template>

            <span v-if="props.column.field === 'warranty_data'">
              <p>

                <div class="mb-1">
                  <template v-if="props?.row?.warranty_date">
                    Expiration Date:  <b>{{ props?.row?.warranty_date }}</b>
                </template>
                <template v-else>
                  <b-badge variant="light-danger">N/A</b-badge>
                </template>
                </div>
                Days Left: <b>{{props?.row?.warranty }}</b>
              </p>
            </span>

            <div v-if="props.column.field === 'last_assigned_data'">
              <span v-if="!props?.row?.last_assigned">
                  <b-badge class="ml-1" variant="light-danger">
                    Not Set
                  </b-badge>
              </span>
              <span v-else>
                  <b-badge class="ml-1" variant="light-success">
                <span v-if="props?.row?.type !== 'primary'">
                  <!-- Only for Primary Asset -->
                  <span>{{ props?.row?.last_assigned?.assigned_user?.name }}</span
                  ><br />
                  <span>
                    <b-link
                        :href="
                        'mailto:' +
                        props?.row?.last_assigned?.assigned_user?.email
                      "
                    >
                      {{ props?.row?.last_assigned?.assigned_user?.email }}
                    </b-link>
                  </span>
                </span>
                <span v-if="props?.row?.type == 'primary'">
                  <p>{{ props?.row?.last_assigned?.asset_assigned_to?.title }}</p>
                  <p v-if="props?.row?.last_assigned">
                    Company Sl. -
                    {{
                      props?.row?.last_assigned?.asset_assigned_to
                          ?.company_serial_number
                    }}
                  </p>
                </span>
              </b-badge>
              </span>
            </div>

            <!-- status -->
            <template v-if="props?.column?.field === 'format_status'">
              <template v-if="props?.row?.status == 'Available'">
                <b-badge variant="light-success">
                  {{ props?.row?.status }}
                </b-badge>
              </template>
              <template v-else-if="props?.row?.status == 'Damaged'">
                <b-badge variant="light-danger">
                  {{ props?.row?.status }}
                </b-badge>
              </template>
              <template v-else-if="props?.row?.status == 'Assigned'">
                <b-badge variant="light-warning">
                  {{ props?.row?.status }}
                </b-badge>
              </template>
            </template>
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <template v-if="$permissionAbility(ASSET_EDIT, permissions)">
                    <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Show</span>
                    </b-dropdown-item>
                  </template>

                  <template v-if="$permissionAbility(ASSET_EDIT, permissions)">
                    <b-dropdown-item v-on:click="onShow(props.row)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                  </template>

                  <template
                    v-if="$permissionAbility(ASSET_DELETE, permissions)"
                  >
                    <b-dropdown-item v-on:click="onDelete(props.row.id)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50', '150', '200']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
        id="modal-asset-form"
        centered
        :title="modelType == 'editModel' ? 'Edit' : 'Create'"
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
        size="lg"
      >
        <validation-observer ref="assetValidation">
          <b-form v-on:submit.prevent="validationForm">
            <b-row class="p-1">
              <template v-if="asset_image">
                <b-col md="12" class="mb-2">
                  <div>
                    <label>Asset Image</label>
                    <b-img
                      center
                      :src="asset_image"
                      alt="Center image"
                      class="custom-asset-image"
                    ></b-img>
                  </div>
                </b-col>
              </template>
              <b-col md="6">
                <b-form-group
                  label="Title "
                  label-for="title"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    vid="title"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="title"
                      type="text"
                      v-model="title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Asset Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Asset Type "
                  label-for="assetTypeId"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="asset type"
                    vid="asset_type"
                    rules="required"
                  >
                    <v-select
                      id="assetTypeId"
                      placeholder="Choose Asset Type"
                      v-model="asset_type_modal"
                      :options="assetTypeConstant"
                      :reduce="(option) => option.value"
                      label="name"
                      :disabled="isEditAssetTypeDisabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Asset Category "
                  label-for="assetTypeId"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="asset category"
                    vid="asset_category_id"
                    rules="required"
                  >
                    <v-select
                      id="assetCategoryId"
                      placeholder="Choose Asset Category"
                      v-model="assetCategoryId"
                      v-on:input="getCategoryWiseAttr"
                      :options="selectAssetCategoryIdOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      :disabled="isEditAssetCategoryIdDisabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- for create -->

              <b-col
                md="6"
                v-for="(item, index) in categoryAttributesArray"
                :key="index"
              >
                <b-form-group
                  :label="item.name"
                  :label-for="item.name"
                  class="required-label"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="item.name"
                    :vid="item.name"
                    :rules="+item.is_numeric ? 'required|custom_gb_rule' : 'required'"
                  >
                    <b-form-input
                      :id="item.name"
                      type="text"
                      v-model="attributeValue[index]"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Input Data"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- for edit -->
              <b-col
                md="6"
                v-for="(item, index) in editAttributeValueOptions"
                :key="index"
              >
                <b-form-group :label="item.name" :label-for="item.name">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="item.name"
                    :vid="item.name"
                  >
                    <b-form-input
                      :id="item.name"
                      type="text"
                      v-model="editAttributeValue[index]"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Input Data"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Company Serial Number "
                  label-for="companySerialNumber"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="company serial number"
                    vid="company_serial_number"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="companySerialNumber"
                      type="text"
                      v-model="company_serial_number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Serial Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Manufacturer Serial Number "
                  label-for="manufacturerSerialNumber"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="manufacturer serial number"
                    vid="manufacturer_serial_number"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="manufacturerSerialNumber"
                      type="text"
                      v-model="manufacturer_serial_number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Manufacturer Serial Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" lg="6">
                <b-form-group label="Currency *" label-for="currency">
                  <ValidationProvider
                    name="currency"
                    v-slot="{ errors }"
                    vid="currency"
                    rules="required"
                  >
                    <v-select
                      id="currency"
                      placeholder="Choose a Currency"
                      v-model="selectedCurrency"
                      :options="currencyOption"
                      :reduce="(option) => option?.currency"
                      label="currency"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Purchase Cost "
                  label-for="cost"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cost"
                    vid="cost"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="cost"
                      type="number"
                      v-model="cost"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Cost"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Purchase Receipt "
                  label-for="purchase_receipt"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="purchase receipt"
                    rules="size:3048"
                    vid="purchase_receipt"
                  >
                    <b-form-file
                      id="purchase_receipt"
                      v-model="purchaseReceipt"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Purchase Date "
                  label-for="purchaseDate"
                  class="required-label"
                >
                  <ValidationProvider
                    name="purchase date"
                    v-slot="{ errors }"
                    vid="purchase_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="purchaseDate"
                      v-model="purchase_date"
                      :state="errors.length > 0 ? false : null"
                      locale="en-US"
                      today-button
                      close-button
                      reset-button
                      @context="onContextPurchaseDate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Warranty Type" label-for="warrantyType">
                  <v-select
                    id="warrantyType"
                    placeholder="Choose Warranty Type"
                    v-model="warranty_type"
                    :options="warrantyTypeValueOption"
                    :reduce="(option) => option.value"
                    label="name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Warranty Duration"
                  label-for="warrantyDuration"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="warranty duration"
                    rules="min_value:0"
                    vid="warrantyDuration"
                  >
                    <b-form-input
                      id="warrantyDuration"
                      type="number"
                      v-model="warranty_duration"
                      placeholder="Warranty Duration"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <template v-if="asset_image">
                  <b-form-group
                    label="Update Asset Image"
                    label-for="asset_image"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="asset image"
                      rules="size:2048|ext:jpeg,png,jpg"
                      vid="asset_image"
                    >
                      <b-form-file
                        id="asset_image"
                        v-model="file"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <template v-else>
                  <b-form-group
                    label="Asset Image "
                    label-for="asset_image"
                    class="required-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="asset image"
                      rules="required|size:2048|ext:jpeg,png,jpg"
                      vid="asset_image"
                    >
                      <b-form-file
                        id="asset_image"
                        v-model="file"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Status "
                  label-for="status"
                  class="required-label"
                >
                  <ValidationProvider
                    name="status"
                    v-slot="{ errors }"
                    vid="status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      placeholder="Choose Status"
                      v-model="status"
                      :options="statusValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- For edit -->
              <b-col md="6" v-if="modelType === 'editModel'">
                <div
                  v-for="(item, index) in categoryAttributesArray"
                  :key="index"
                >
                  <div v-if="assetCategoryId">
                    <b-form-group :label="item.name" :label-for="item.name">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          :id="item.name"
                          type="text"
                          v-model="attributeValue[index]"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Input Data"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Description "
                  label-for="description"
                  class="required-label"
                >
                  <validation-provider
                    name="description"
                    vid="description"
                    #default="{ errors }"
                    rules="required"
                  >
                    <quill-editor
                      v-model="description"
                      :options="editorOption"
                      :style="{
                        height: '13rem !important',
                        paddingBottom: '5rem !important',
                      }"
                    >
                      <div id="toolbar" slot="toolbar">
                        <!-- Add a bold button -->
                        <span class="ql-formats">
                          <button class="ql-bold">Bold</button>
                          <button class="ql-italic">Italic</button>
                          <button class="ql-underline">Underline</button>
                          <button class="ql-strike">Strike</button>
                          <button class="ql-blockquote"></button>
                          <button class="ql-list" value="ordered"></button>
                          <button class="ql-list" value="bullet"></button>
                          <button class="ql-script" value="sub"></button>
                          <button class="ql-script" value="super"></button>
                          <button class="ql-indent" value="-1"></button>
                          <button class="ql-indent" value="+1"></button>
                          <button class="ql-direction" value="rtl"></button>
                          <button class="ql-align" value=""></button>
                          <button class="ql-align" value="center"></button>
                          <button class="ql-align" value="right"></button>
                          <button class="ql-align" value="justify"></button>
                          <select class="ql-color"></select>
                          <select class="ql-background"></select>
                          <select class="ql-size">
                            <option value="small" />

                            <option selected />
                            <option value="large" />
                            <option value="huge" />
                          </select>

                          <select class="ql-font"></select>
                          <select class="ql-header">
                            <option value="1">Heading 1</option>
                            <option value="2">Heading 2</option>
                            <option value="3">Heading 3</option>
                            <option value="4">Heading 4</option>
                            <option value="5">Heading 5</option>
                            <option value="6">Heading 6</option>
                            <option selected>Normal</option>
                          </select>

                          <button class="ql-link"></button>
                        </span>
                      </div>
                    </quill-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- loading button -->
            <template v-if="isAssetFormSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
        id="asset-description-log-modal"
        scrollable
        title="Description History"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <b-card-text class="mt-1">
          <app-timeline>
            <app-timeline-item
              v-for="(log, index) in assetAcitivityLog"
              :key="index"
              variant="primary"
            >
              <small class="text-muted">{{
                formatDateTime(log?.created_at)
              }}</small>
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0"
              >
                <p v-html="formatLogDescription(log)"></p>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BRow,
  BCol,
  BModal,
  BSpinner,
  BLink,
  BFormDatepicker,
  BFormFile,
  BCardText,
  BFormCheckboxGroup,
  BFormCheckbox,
  BDropdownForm,
  BDropdownGroup,
  BDropdownDivider,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";

import { required, max, size, ext, min_value } from "@validations";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { assetTypeConstant } from "@/helpers/constant/assetTypeConstant";
import {
  ASSET_EXPORT,
  ASSET_CREATE,
  ASSET_EDIT,
  ASSET_DELETE,
} from "@/helpers/permissionsConstant";
import { FileMinusIcon } from "vue-feather-icons";
import flatPickr from 'vue-flatpickr-component'
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'
extend("custom_gb_rule", {
  ...required,
  validate: (value) => {
    const pattern = /gb/i;
    return pattern.test(value) || 'Input must contain "gb" or "GB" or "Gb"';
  },
});

export default {
  name: "AssetView",
  components: {
    FormatCurrency,
    AppTimeline,
    AppTimelineItem,
    BCardText,
    BForm,
    BButton,
    BImg,
    BLink,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormDatepicker,
    BFormFile,
    quillEditor,
    BFormCheckboxGroup,
    BFormCheckbox,
    BDropdownForm,
    BDropdownGroup,
    BDropdownDivider,
    FileMinusIcon,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      customRules: {
        custom_gb_rule: (value) => {
          // Use a regular expression to check if the input contains "gb" or "GB" or "Gb"
          const pattern = /gb/i; // 'i' makes the match case-insensitive
          return (
            pattern.test(value) || 'Input must contain "gb" or "GB" or "Gb"'
          );
        },
      },

      selectedAttribute: [],
      attributeCheckboxSelected: 0,

      ASSET_EXPORT,
      ASSET_CREATE,
      ASSET_EDIT,
      ASSET_DELETE,
      // dynamic form
      loopForm: [],
      //asset Filtering
      delayTimer: null,
      searchTerm: "",
      searchAttributeValue: [],
      department_id: "",
      filter_status: "",
      filterStartDate: "",
      filterRangeDate: "",
      filterEndDate: "",
      filter_start_date: "",
      filter_end_date: "",
      selectDepartmentIdOptions: [],
      filteredStatusValueOption: [
        { name: "Available", value: "Available" },
        { name: "Assigned", value: "Assigned" },
        { name: "Damaged", value: "Damaged" },
      ],

      openAttributeCheckbox: false,
      modelType: "",
      assetAcitivityLog: [],
      assetId: "",
      asset_type_modal: "",
      asset_category_id: "",
      assetCategoryId: "",
      editAssetId: "",
      isAttributeDropdownDisabled: false,
      title: "",
      filter_asset_type: "",
      filter_asset_category_id: "",
      selectAssetTypeIdOptions: [],
      assetTypeConstant,
      editAttributeValueOptions: [],
      categoryAttributesArray: [],
      categoryAttributesEditArray: [],
      filteredAttributes: [],
      assetAttribute: [],
      selectAssetCategoryIdOptions: [],
      isCategoryDisabled: false,
      isEditAssetCategoryIdDisabled: false,
      isEditAssetTypeDisabled: false,
      selectAttributeIdOptions: [],
      company_serial_number: "",
      manufacturer_serial_number: "",
      cost: "",
      purchase_date: "",
      asset_image: "",
      warranty_duration: "",
      warranty_type: "",
      attributeValue: [],
      editAttributeValue: [],
      asset_attribute_values: [],
      asset_attribute: [],
      file: null,
      purchaseReceipt: null,
      selectedCurrency: "",
      currencyOption: [],
      description: "",
      status: "",
      statusValueOption: [
        {
          name: "Available",
          value: "Available",
        },
        {
          name: "Damaged",
          value: "Damaged",
        },
      ],
      warrantyTypeValueOption: [
        {
          name: "Days",
          value: "Days",
        },
        {
          name: "Months",
          value: "Months",
        },
        {
          name: "Years",
          value: "Years",
        },
      ],
      columns: [
        {
          label: "Asset Image",
          field: "format_image",
          sortable: false,
        },
        {
          label: "Asset Info",
          field: "format_info",
          sortable: false,
        },
        {
          label: "Cost Info",
          field: "format_cost_info",
          sortable: false,
        },
        {
          label: "Purchase Date",
          field: "purchase_date",
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: "Warranty",
          field: "warranty_data",
          sortable: false,
        },
        {
          label: "Last Assigned",
          field: "last_assigned_data",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      params: [],
      pageLength: 50,
      isAssetFormSubmitLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),

    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    formatAssetType(value) {
      const foundItem = this.assetTypeConstant.find(
        (item) => item.value === value
      );

      return foundItem.name;
    },
    async getSelectedAttribute() {
      if (this.selectedAttribute.length > 0) {
        this.attributeCheckboxSelected = 1;
        this.isCategoryDisabled = true;

        const [loadAllAttribute] = await Promise.all([
          this.getAssetCategoryAttributeItems(),
        ]);

        const allAttributes = loadAllAttribute?.data?.data;

        if (Array.isArray(allAttributes)) {
          this.filteredAttributes = allAttributes.filter((attribute) =>
            this.selectedAttribute.includes(attribute.id)
          );

          this.assetAttribute = this.filteredAttributes.map((item) => item.id);
        }
      } else {
        this.isCategoryDisabled = false;
        this.attributeCheckboxSelected = 0;
        this.filteredAttributes = [];
        this.getCategoryWiseAttr();
      }
    },

    async getCategoryWiseAttr() {
      if (this.assetCategoryId && this.attributeCheckboxSelected != 1) {
        this.isAttributeDropdownDisabled = true;

        const [loadAttribute] = await Promise.all([this.getAttributeItems()]);

        this.categoryAttributesArray =
          loadAttribute?.data?.data?.attributes?.data;
      } else {
        this.isAttributeDropdownDisabled = false;
        this.categoryAttributesArray = [];
        this.searchAttributeValue = [];
      }

      this.assetAttribute = this.categoryAttributesArray.map((item) => item.id);
      this.assetFiltering();
    },

    // for edit
    async getPreviousAttributeValues() {
      if (this.assetCategoryId) {
        const [editAttributes] = await Promise.all([
          this.getSingleAssetItems(),
        ]);

        this.editAttributeValueOptions =
          (editAttributes?.data?.data?.assetAttributeValues?.data).map(
            (item) => {
              let name = item.attribute_name;

              this.editAttributeValue.push(item.value);
              this.assetAttribute.push(item.attribute_id);

              return {
                name,
                value: item.value,
              };
            }
          );
      }
    },

    openAttributeOption() {
      this.openAttributeCheckbox = !this.openAttributeCheckbox;
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YY, h:mm: a");
      }
    },
    formatLogDescription(value) {
      let description = "";

      if (value?.event == "updated" || value?.event == "created")
        description = value?.properties?.attributes?.description;

      return description;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;

        if (item.field == "format_info") {
          field = "title";
        }

        if (item.field == "format_email") {
          field = "email";
        }

        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },

    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM DD, YYYY");
      }
      return "Not Set";
    },
    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },

    formatFnTableStatus(status) {
      return status ? "Active" : "Inactive";
    },
    onShowPage(id) {
      this.$router.push({
        name: "admin-assets-details",
        params: { id },
      });
    },

    async showDescriptionHistory(id) {
      const assetAcitivityLog = await this.getAssetAcitivityLog(id);

      this.assetAcitivityLog = [];
      this.assetAcitivityLog = assetAcitivityLog?.data?.data;

      // this.formatLogDescription(this.assetAcitivityLog);
      this.$bvModal.show("asset-description-log-modal");
    },
    showModal() {
      this.$bvModal.show("modal-asset-form");

      if (this.modelType == "editModel") {
        this.isEditAssetCategoryIdDisabled = true;
        this.isEditAssetTypeDisabled = true;
      }
    },

    hiddenModal() {
      this.$bvModal.hide("modal-asset-form");
      this.resetModal();
      this.assetFiltering();
      this.isAttributeDropdownDisabled = false;
    },

    resetModal() {
      this.assetId = "";
      this.modelType = "";
      this.title = "";
      this.asset_type_modal = "";
      this.asset_category_id = "";
      this.assetCategoryId = "";
      this.cost = "";
      this.company_serial_number = "";
      this.manufacturer_serial_number = "";
      this.purchase_date = "";
      this.warranty_duration = "";
      this.warranty_type = "";
      this.file = null;
      this.purchaseReceipt = null;
      this.asset_image = "";
      this.description = "";
      this.status = "";
      this.editAttributeValue = [];
      this.assetAttribute = [];
      this.attributeValue = [];
      this.categoryAttributesArray = [];
      this.editAttributeValueOptions = [];
      this.isEditAssetCategoryIdDisabled = false;
      this.isEditAssetTypeDisabled = false;
      this.selectedCurrency = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.selectedCurrency = value?.currency;
      this.assetId = value?.id;
      this.asset_type_modal = value?.type;
      this.asset_category_id = value?.asset_category_id;
      this.assetCategoryId = value?.asset_category_id;
      this.title = value?.title;
      this.cost = value?.cost;
      this.company_serial_number = value?.company_serial_number;
      this.manufacturer_serial_number = value?.manufacturer_serial_number;
      this.purchase_date = value?.purchase_date;
      this.warranty_duration = value?.warranty;
      this.warranty_type = "Days";
      this.asset_image = value?.asset_image;
      this.description = value?.description;
      this.status = value?.status;
      this.showModal();
      this.getPreviousAttributeValues();
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/assets/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Asset Deleted Successfully ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    async getAssetItems(params) {
      return await this.$api.get("api/assets?include=assetAttributeValues", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          f: params.f,
          assetAttribute: params.assetAttribute,
          attributeCheckboxSelected: params.attributeCheckboxSelected,
          asset_type: params.asset_type,
          asset_category_id: params.asset_category_id,
          department_id: params.department_id,
          status: params.status,
          start_date: params.start_date,
          end_date: params.end_date,
        },
      });
    },

    async getAssetTypeItems() {
      return await this.$api.get("api/asset-types/active-all");
    },

    async getAssetCategoryItems() {
      return await this.$api.get(
        "api/asset-categories/active-all?include=attributes"
      );
    },

    async getAssetCategoryAttributeItems() {
      return await this.$api.get("api/asset-category-attributes/all");
    },

    async getAttributeItems() {
      return await this.$api.get(
        `api/asset-categories/${this.assetCategoryId}?include=attributes`
      );
    },

    async getSingleAssetItems() {
      const id = this.assetId;
      return await this.$api.get(
        `api/assets/${id}?include=assetAttributeValues`
      );
    },

    //Export Asset File
    async exportAsset() {
      try {
        const { data } = await this.$api.post(`/api/asset-exports`);
        window.location.href = data.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async getAssetAcitivityLog(id) {
      const assetId = id;
      return await this.$api.get(`api/assets/${assetId}/activity-log`);
    },
    async getCurrencies() {
      return await this.$api.get("api/currencies/all");
    },
    async loadItems() {
      try {

        this.handleDateRangeChange()

        const [assets, currencies, assetCategories, assetCategoryAttribute] =
          await Promise.all([
            this.getAssetItems({
              show: this.serverParams.perPage,
              page: this.serverParams.page,
              sort: this.serverParams.sort,
              q: this.searchTerm,
              f: this.searchAttributeValue,
              assetAttribute: this.assetAttribute,
              attributeCheckboxSelected: this.attributeCheckboxSelected,
              asset_type: this.filter_asset_type,
              asset_category_id: this.assetCategoryId,
              department_id: this.department_id,
              status: this.filter_status,
              start_date: this.filterStartDate,
              end_date: this.filterEndDate,
            }),
            this.getCurrencies(),
            this.getAssetCategoryItems(),
            this.getAssetCategoryAttributeItems(),
          ]);

        // assets Response
        const data = assets?.data?.data;
        this.rows = data;

        this.currencyOption = (currencies?.data?.data || []).map((item) => {
          return {
            currency: item?.currency,
          };
        });

        //filter assetTypes option
        this.selectAssetCategoryIdOptions = (
          assetCategories?.data?.data || []
        ).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });

        // Attribute options
        this.selectAttributeIdOptions = (
          assetCategoryAttribute?.data?.data || []
        ).map((item) => {
          let text = item.name;
          return {
            text,
            value: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    // AssetFiltering
    async loadDepartments() {
      try {
        const { data } = await this.$api.get("/api/departments");
        this.selectDepartmentIdOptions = data?.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async assetFiltering() {
      this.loadItems();
    },

    async assetFilteringWithAttributeId(id, index) {
      this.loadItems();
    },

    validationForm: async function () {
      this.$refs.assetValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isAssetFormSubmitLoading = true;

              const formData = new FormData();
              formData.append("_method", "PUT");
              if (this.warranty_duration == 0)
                formData.append("warranty_duration", 0);
              if (this.warranty_duration > 0)
                formData.append("warranty_duration", this.warranty_duration);

              if (this.file) formData.append("asset_image", this.file);
              if (this.selectedCurrency)
                formData.append("currency", this.selectedCurrency);
              if (this.title) formData.append("title", this.title);
              if (this.asset_type_modal)
                formData.append("type", this.asset_type_modal);

              if (this.asset_category_id)
                formData.append("asset_category_id", this.asset_category_id);

              if (this.cost) formData.append("cost", this.cost);
              if (this.company_serial_number)
                formData.append(
                  "company_serial_number",
                  this.company_serial_number
                );
              if (this.manufacturer_serial_number)
                formData.append(
                  "manufacturer_serial_number",
                  this.manufacturer_serial_number
                );
              if (this.purchase_date)
                formData.append("purchase_date", this.purchase_date);
              if (this.purchaseReceipt)
                formData.append("purchase_receipt", this.purchaseReceipt);
              if (this.warranty_type)
                formData.append("warranty_type", this.warranty_type);
              if (this.description)
                formData.append("description", this.description);
              if (this.status) formData.append("status", this.status);

              await this.editAttributeValue.forEach(function (element) {
                formData.append("asset_attribute_values[]", element);
              });

              await this.assetAttribute.forEach(function (element) {
                formData.append("asset_attribute[]", element);
              });

              await this.$api.post(`/api/assets/${this.assetId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });

              this.isAssetFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Asset successfully updated",
                },
              });
            } else {
              this.isAssetFormSubmitLoading = true;

              const formData = new FormData();

              formData.append("asset_image", this.file);
              if (this.purchaseReceipt)
                formData.append("purchase_receipt", this.purchaseReceipt);
              formData.append("title", this.title);
              formData.append("type", this.asset_type_modal);
              formData.append("asset_category_id", this.assetCategoryId);
              formData.append("cost", this.cost);
              formData.append(
                "company_serial_number",
                this.company_serial_number
              );
              formData.append(
                "manufacturer_serial_number",
                this.manufacturer_serial_number
              );
              formData.append("purchase_date", this.purchase_date);
              formData.append("warranty_duration", this.warranty_duration);
              formData.append("warranty_type", this.warranty_type);
              formData.append("description", this.description);
              formData.append("status", this.status);
              formData.append("currency", this.selectedCurrency);

              await this.attributeValue.forEach(function (element) {
                formData.append("asset_attribute_values[]", element);
              });

              await this.assetAttribute.forEach(function (element) {
                formData.append("asset_attribute[]", element);
              });

              await this.$api.post("/api/assets", formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });

              this.isAssetFormSubmitLoading = false;
              this.hiddenModal();
              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Asset successfully created",
                },
              });
            }
          } catch (error) {
            this.isAssetFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.assetValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.asset-table {
  font-size: 13px !important;
  min-height: 137px !important;
  white-space: nowrap !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.description-para p {
  margin-bottom: 0rem !important;
  font-size: 13px !important;
}
.custom-font {
  font-size: 13px !important;
}
.custom-asset-image {
  height: 125px !important;
  width: 135px !important;
}
.btn-adjust {
  margin-top: 20%;
}
.scrollable-checkbox-group {
  max-height: 200px;
  overflow-y: auto;
}
.required-label label::after {
  content: " *";
  color: red;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
